<template>
  <div class="won">
    <Landing
      :canAnimate="true"
      :smallDevice="false"
      :remainingPizzas="0"
      :totalEntries="0"
      ref="landing"
      :isWonPage="true"
      :shareLinks="shareLinks"
      @download-share="handleDownload"
    />
  </div>
</template>

<script>
import Landing from '@/components/Landing.vue';

export default {
  emits: ['page-ready'],
  inject: ['tracking'],
  components: {
    Landing,
  },
  data: () => ({
    prefillCopy: 'I just coded a virtual pizza. Check out Code4PizzaODU to code yours!',
    url: `${process.env.VUE_APP_SHARE_URL}winnershare`,
  }),
  computed: {
    facebookShareLink() {
      return `https://www.facebook.com/sharer.php?u=${this.url}&quote=${encodeURIComponent(this.prefillCopy)}`;
    },
    twitterShareLink() {
      return `https://twitter.com/intent/tweet?text=${this.prefillCopy}${encodeURIComponent(' @oducs')}&url=${this.url}`;
    },
    shareLinks() {
      return {
        facebook: this.facebookShareLink,
        twitter: this.twitterShareLink,
      };
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit('page-ready');
      this.tracking.trackPageView('Share Landing Page');
    }, 1000);
  },
};
</script>

<style lang="scss">
.won {
  .landing {
    &__top {
      min-height: 450px;
    }
    &__share {
      position: absolute;
      width: 30%;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 15%);
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__image {
        display: block;
        width: 100%;
      }
      .shadow {
        width: 80%;
        margin-top: 20px;
      }
    }

    .back-buttons {
      position: absolute;
      top: 30px;
      left: 30px;
      display: flex;
      justify-content: center;
      z-index: 10;
      a {
        &.back-link {
          color: #17385C;
          background: $gameYellow;
          border: 2px solid #FFFFFF;
          text-decoration: none;
          margin-right: 20px;
          font-family: $headlineFont;
          font-size: 9px;
          text-align: center;
          text-transform: uppercase;
          padding: 10px;
          transition: border-color 300ms $easeOutQuad, background 300ms $easeOutQuad;
           &:hover {
            background: white;
            border-color: $gameYellow;
          }
        }
      }
    }

    .winner-copy {
      &__share {
        &__copy {
          font-family: $headlineFont;
          font-size: 12px;
          line-height: 16px;
          color: white;
          width: 100%;
        }
        &__list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          list-style: none;
          padding: 0;
          margin-bottom: 25px;
          &__item {
            width: 28px;
            height: 28px;
            background: white;
            border: 1px solid white;
            margin: 0 10px;
            a {
              cursor: pointer;
            }
            &.download {
              background: $gameYellow;
              display: flex;
              justify-content: center;
              align-items: center;
              a {
                width: 75%;
              }
            }
            svg {
              display: block;
            }
          }
        }
      }
    }
    &__bottom {
      height: 50vh;
      min-height: 450px;
      &__copy {
        bottom: 55%;
        p {
          max-width: 1090px;
        }
      }
    }
  }
}
</style>
